<template>
  <div>
    <span
      @click="onFocus"   
      v-show="!editing"
    >
      <h2 v-if="isHeader && value">{{ value }}</h2>
      <h2 v-if="isHeader && !value">{{placeholderText}}</h2>
      <p  v-if="!isHeader && value">{{ limitedValue }}</p>
      <p  v-if="!isHeader && !value">{{placeholderText}}</p>
    </span>
    <b-input-group
      @blur="editing = false"
      v-show="editing">
      <b-form-input ref="insightHeader" v-if="isHeader" :class="isAnnotation ? 'annotation-title' : 'insight-text-header'"
      v-on:focus.native="onFocus" v-on:blur.native="onBlur" type="text" :placeholder="placeholderText" :value="value" @input="updateValue"></b-form-input>
      <b-form-textarea
        v-else :class="isAnnotation ? 'annotation-description-text' : 'insight-text-desc'"
        ref="insightDesc"
        :value="this.limitedValue"
        size="sm"
        @input="updateValue"
        :placeholder="placeholderText"
        rows="20"
        :maxlength="maxDescLen"
        id="textarea-rows"
        v-on:focus.native="onFocus"
        v-on:blur.native="onBlur"
      ></b-form-textarea>
      <b-input-group-append class="edit-icon" v-if="!editing" is-text @click="onFocus" v-b-tooltip.hover title="Click to edit">
        <b-icon icon="pencil"></b-icon>
      </b-input-group-append>
    </b-input-group>
    <p v-if="editing && !isAnnotation && !isHeader" class="pt-2">Characters: {{ descCharCountString }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editing: false,
      maxDescLen: 800
    };
  },
  props: {
    value: {
      type: String,
      required: false
    },
    placeholderText: {
      type: String,
      required: false
    },
    isHeader: {
      type: Boolean,
      required: true
    },
    isAnnotation: {
      type: Boolean,
      default: false
    },
    isAnnotationType: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    limitedValue() {
      if(this.value) {
        return this.isDescription ? this.value.substring(0, this.maxDescLen) : this.value;
      } else {
        return '';
      }
    },
    isDescription() {
      return !(this.isHeader || this.isAnnotation);
    },
    descCharCountString() {
      return `${this.value ? this.value.length : 0} / ${this.maxDescLen}`;
    }
  },
  methods: {
    async onFocus() {
      this.editing = true;
      await this.$nextTick();
      if (this.isHeader)
        this.$refs.insightHeader.focus();
      else
        this.$refs.insightDesc.focus();
    },
    onBlur() {
      this.editing = false;
      if (this.isAnnotationType){
        this.$emit('updateAnnotationType');
      } else if (this.isAnnotation){
        this.$emit('updateAnnotation');        
      }
    },
    updateValue(newValue) {
      this.$emit('input', newValue);
    }
  }
};
</script>

<style scoped>
.edit-icon:hover {
  cursor: pointer;
}

.insight-text {
  padding: 0;
}

h2, .insight-text-header {
  font-size: 1em;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 0;
}

.annotation-title {
  font-weight: 700;
  text-transform: none;
  letter-spacing: 0;
  font-size: 0.8em !important;
}

.annotation-description-text {
  height: 100px;
  width: 100%;
  resize: none;
  overflow-y: auto;
}

p {
  padding: 0;
  color: var(--grey-900);
  font: normal normal normal 0.875rem/1.063rem Inter;
}

.insight-text-desc {
  padding: 0;
  color: var(--grey-900);
  font: normal normal normal 0.875rem/1.063rem Inter;
  resize: none;
  height: 250px;
}

@media (max-width: 1300px) {
  .insight-text-desc {
    height: 200px; /* Adjusted height for smaller screens */
  }
}
</style>