<template>
  <div id="insights-panel" class="insights-panel panel-wide" v-if="active">
    <insights-title
      ref="insightsTitle"
      rank='primary'
      @insightLayersPresent="handleInsightLayersPresent"
      @removingAllLayers="removingAllLayers" />
    <div ref="layers-panel-content" class="layers-panel-content no-top-margin-all-descendants" v-if="this.viewerMode === 'Insights' && !sceneViewCompositionMode">
        <div class="d-flex flex-row align-items-center" id="insight-form-elements">
          <div id="insight-type-info" class="d-flex flex-row align-items-center">
            <p class="insight-form-label mr-2 font-weight-bold" v-if="editInsightMode || createInsightMode">Insight Type</p>
            <p v-if="editInsightMode && currentInsight" class="bg-white p-2">{{currentInsight.insight_type}}</p>
            <p v-if="createInsightMode" class="bg-white p-2">{{insightTypeSelected}}</p>
          </div>
          
          <div id="insight-status" class="d-flex flex-row align-items-center" v-if="createInsightMode">
            <b-form-checkbox
              id="insight-status-switch"
              class="d-block align-middle ml-1"
              switch
              :checked="newInsightStatus"
              v-model="newInsightStatus"> {{ newInsightStatus? 'Published' : 'Draft' }}
            </b-form-checkbox>
            <b-icon-info-circle id="publish-info-icon" variant='secondary' />
            <b-tooltip target="publish-info-icon" triggers="hover click">
              <span v-if="newInsightStatus">Your insight will be published</span>
              <span v-else>Your insight will be saved as a draft</span>
            </b-tooltip>
          </div>
          <div id="insight-status" class="d-flex flex-row align-items-center" v-else-if="editInsightMode">
            <b-form-checkbox
              id="insight-status-switch"
              class="d-block align-middle ml-1"
              switch
              :checked="currentInsightStatus"
              v-model="currentInsightStatus"> {{ currentInsightStatus? 'Published' : 'Draft' }}
            </b-form-checkbox>
            <b-icon-info-circle id="publish-info-icon" variant='secondary' />
            <b-tooltip target="publish-info-icon" triggers="hover click">
              <span v-if="currentInsightStatus">Your insight will be published</span>
              <span v-else>Your insight will be saved as a draft</span>
            </b-tooltip>
          </div>
        </div>

        <h2 v-if="!editInsightMode && !createInsightMode" class="pb-3 insight-title">{{ currentInsight != null ? currentInsight.title : '' }} <span v-if="currentInsight != null ? currentInsight.insight_status == 'Draft': false" style="color:#FFC107;">(Draft)</span></h2>
        <insights-text v-if="createInsightMode" v-model="newInsightTitle" :isAnnotation="false" :isHeader="true" placeholderText="Enter title here..."></insights-text>
        <insights-text v-if="editInsightMode && currentInsight" v-model="currentInsight.title" :isAnnotation="false" :isHeader="true" placeholderText="Enter title here..."></insights-text>
        <div v-if="!annotationsContainerExpanded" :class="editInsightMode ? 'scrollable-container-edit-mode' : 'scrollable-container'">
          <p v-if="!editInsightMode && !createInsightMode" ref="insight_description" class="insight-description">{{ currentInsightDescription }}</p>
          <insights-text class="insight-text-description" v-if="createInsightMode" v-model="newInsightDescription" :isAnnotation="false" :isHeader="false" placeholderText="Enter description here..."></insights-text>
          <insights-text class="insight-text-description" v-if="editInsightMode && currentInsight" v-model="currentInsight.description" :isAnnotation="false" :isHeader="false" placeholderText="Enter description here..."></insights-text>
        </div>
        <div v-else>
          <p v-if="!editInsightMode && !createInsightMode" ref="insight_description" class="insight-description">{{ currentInsightDescription }}</p>
        </div>
        <div v-if="!createInsightMode && currentInsights.length > 0 && currentInsightIsSceneView" class="annotations-container">
          <div v-if="this.currentInsightIsSceneView && !createInsightMode || !editInsightMode" class="d-flex flex-row align-items-center justify-content-between annotations-header">
            <h5 class="pb-2 annotations">Annotations</h5>
            <b-icon @click="toggleAnnotationsContainer" v-if="annotationsContainerExpanded" class="pb-2 annotations-ctn-btn" icon="caret-down-fill"/>
            <b-icon @click="toggleAnnotationsContainer" v-else class="pb-2 annotations-ctn-btn" icon="caret-up-fill"/>
          </div>
          <hr v-if="this.currentInsightIsSceneView && !createInsightMode || !editInsightMode" class="mb-3" />
          <div ref="insight_annotations_container" :class="annotationsContainerExpanded ? 'insight-annotations-container-2' : 'insight-annotations-container'" v-if="!createInsightMode && this.currentInsightIsSceneView">  
            
            <div ref="insight_annotations" class="insight-annotations">
              <div v-if="insightHasNoPins && userCanAuthorInsights" class="insight-no-pins-conatiner">
                <span class="d-flex justify-content-center">No annotations yet.</span>
                <b-button variant="link" @click="enterEditInsightMode" class="annotation-link">+ Add</b-button>
              </div>
              <div class="pb-1" v-for="pinType in currentInsight.pin_types" :key="pinType.id">
                <div class="d-flex flex-row align-items-center justify-content-between"> <!-- pin header row.  style, title (if in display mode), delete (if in edit mode) -->
                  <div class="d-flex flex-row"><!-- pinType icon -->
                    <span ref="pinTypes" class="annotation-bg-circle rounded-circle" :style="`background-color:${pinType.colour};`">
                      <img v-if="!!pinType.icon"
                        :src="`/annotation_icons/${pinType.icon}.png`"
                        class="annotation-icon"/>
                      <img v-else
                        :src="`/annotation_icons/bullet-point.png`"
                        class="annotation-icon"/>
                    </span>
                    <hr class="pin-icon-spacer" />
                    <div >
                      <h5 v-if="!editInsightMode && !createInsightMode" :class="['ml-1 annotation-title', pinType.name === newAnnotationTypeName ? 'dummy-annotation-title' : '']">{{ pinType.name }}</h5>
                      <p v-if="!editInsightMode && !createInsightMode && pinType.detailsOpen" class="ml-1 annotation-desc">{{ pinType.description }}</p>
                    </div>
                    <div :class="annotationsColumnStyling">
                      <insights-text @updateAnnotationType="updateAnnotationType(pinType.id)" v-if="createInsightMode" v-model="newAnnotationTitle" :isAnnotationType="true" :isHeader="true" placeholderText="Enter title here..."></insights-text>
                      <insights-text @updateAnnotationType="updateAnnotationType(pinType.id, pinType.name, pinType.description)" v-if="editInsightMode" v-model="pinType.name" :isHeader="true" :isAnnotationType="true" placeholderText="Enter title here..."></insights-text>
                      <insights-text @updateAnnotationType="updateAnnotationType(pinType.id)" v-if="createInsightMode" v-model="newAnnotationDescription" :isAnnotation="true" :isAnnotationType="true" :isHeader="false" placeholderText="Enter description here..."></insights-text>
                      <insights-text @updateAnnotationType="updateAnnotationType(pinType.id, pinType.name, pinType.description)" v-if="editInsightMode" v-model="pinType.description" :isAnnotation="true" :isAnnotationType="true" :isHeader="false" placeholderText="Enter description here..."></insights-text>
                    </div>
                  </div>
                  <div title="delete annotation type" v-if="editInsightMode" :disabled="pinType.deleteInProgress" class="p-1 insight-title-bar-button">
                    <b-spinner small v-if="pinType.deleteInProgress" />
                    <b-icon-trash v-else variant="danger" @click="openDeleteAnnotationTypeModal(pinType.id)"/>
                  </div>
                  <div class="pin-type-visibility" v-if="!editInsightMode">
                    <b-icon class="annotations-btn" @click="togglePinTypeDetails(pinType, true)" v-if="!pinType.detailsOpen" icon="caret-up-fill"></b-icon>
                    <b-icon class="annotations-btn" @click="togglePinTypeDetails(pinType, false)" v-else icon="caret-down-fill"></b-icon>
                  </div>
                  <b-modal :id="`confirm-annotation-type-delete-${pinType.id}`" centered hide-header-close title="Are you sure?" >
                    <p>If you delete the annotation type then all annotations of this type on this insight will also be deleted.  Are you sure you want to delete this annotation type and all of it's associated annotations?</p>
                    <template #modal-footer>
                      <b-button @click='$bvModal.hide(`confirm-annotation-type-delete-${pinType.id}`)' class='float-left'>Cancel</b-button>
                      <b-button @click='deleteAnnotationType(pinType); $bvModal.hide(`confirm-annotation-type-delete-${pinType.id}`)' variant='danger' class='float-right'>Delete</b-button>
                    </template>
                  </b-modal>
                </div>
                <div v-if="pinType.detailsOpen || (editInsightMode && pinType.icon == null)">
                  <div v-for="pin in sortedPins" :key="pin.id" class="pl-3">
                    <div v-if="pin.insight_pin_type_id == pinType.id && (editInsightMode || !(newAnnotationTitle || newAnnotationDescription || (!pin.title && !pin.description)))">
                      <div class="d-flex flex-row align-items-center justify-content-between"> <!-- pin header row.  style, title (if in display mode), delete (if in edit mode) -->
                        <div class="d-flex flex-row ml-4 mt-2"><!-- pin icon -->
                          <span class="annotation-bg-circle rounded-circle" :style="`background-color:${pin.colour};`">
                            <img v-if="!!pin.icon"
                              :src="`/annotation_icons/${pin.icon}.png`"
                              class="annotation-icon"/>
                            <h5
                              v-else
                              class="d-inline-block annotation-tag">
                              {{ pin.tag }}
                            </h5>
                          </span>
                          <hr class="pin-icon-spacer" />
                          <div :class="annotationColumnWidth">
                            <h5 v-if="!editInsightMode && !createInsightMode && pin.title" class="ml-1 annotation-title">{{ pin.title }}</h5>
                            <p v-if="!editInsightMode && !createInsightMode && !pin.hide_text" class="ml-1 annotation-desc">{{ pin.description }}</p>
                          </div>
                          <div :class="annotationsColumnStyling">
                            <insights-text @updateAnnotation="updateAnnotation(pin.id)" v-if="createInsightMode" v-model="newAnnotationTitle" :isAnnotation="true" :isHeader="true" placeholderText="Enter title here..."></insights-text>
                            <insights-text @updateAnnotation="updateAnnotation(pin.id, pin.title, pin.description)" v-if="editInsightMode" v-model="pin.title" :isHeader="true" :isAnnotation="true" placeholderText="Enter title here..."></insights-text>
                            <insights-text @updateAnnotation="updateAnnotation(pin.id)" v-if="createInsightMode" v-model="newAnnotationDescription" :isAnnotation="true" :isHeader="false" placeholderText="Enter description here..."></insights-text>
                            <insights-text @updateAnnotation="updateAnnotation(pin.id, pin.title, pin.description)" v-if="editInsightMode" v-model="pin.description" :isAnnotation="true" :isHeader="false" placeholderText="Enter description here..."></insights-text>
                          </div>
                        </div>
                        <div title="delete annotation" v-if="editInsightMode" :disabled="pin.deleteInProgress" class="p-1 insight-title-bar-button">
                          <b-spinner small v-if="pin.deleteInProgress" />
                          <b-icon-trash variant="danger" v-else @click="deleteAnnotation(pin)"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <a class="toggle-sidebar-button" role="button" title="Toggle sidebar" type="button" @click="toggleNav()">
      <b-icon icon="chevron-left"></b-icon>
    </a>
  </div>
  <div id="closed-layers-panel" class="closed-layers-panel" @click="toggleNav()" v-else>
    <b-icon icon="chevron-right"></b-icon>
  </div>
</template>

<script>
import InsightsTitle from './InsightsTitle';
import InsightsText from '../viewer/InsightsText';
import { mapGetters } from 'vuex';
import { NEW_ANNOTATION_TYPE_NAME } from '@/constants/annotations';
import { AUTHOR_INSIGHTS } from '@/constants/permissions';

export default {
  name: 'InsightsPanel',
  components: {
    InsightsText,
    InsightsTitle
  },
  props: {
    active: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      currentInsightIndex: 0,
      newAnnotationTitle: null,
      newAnnotationDescription: null,
      newInsightDescription: null,
      newInsightTitle: null,
      newInsightStatus: false,
      currentInsightStatus: false,
      currentInsightPinsList: [],
      annotationsContainerExpanded: false,
      updatingInsightResource: false,
      updatingPublishStatus: false
    };
  },
  async mounted() {
    if (this.$route.query.page)
      this.currentInsightIndex = Number(this.$route.query.page);

    this.getCurrentInsightPinsList();

    if (this.editInsightMode) {
      this.currentInsightStatus = (this.currentInsight.insight_status=='Published');
    }

    if (this.createInsightMode) {
      this.newInsightTitle = null;
      this.newInsightDescription = null;
      this.newInsightStatus = true;
    }
  },
  computed: {
    annotationsColumnStyling() {
      return this.editInsightMode ? 'w-75' : ' flex-col ml-1';

    },
    annotationColumnWidth() {
      return this.editInsightMode ? '' : 'w-75';
    },
    currentInsightDescription() {
      if(!this.currentInsight) return null;
      if(!this.annotationsContainerExpanded) return this.currentInsight.description;
      else return `${this.currentInsight.description.slice(0, 45)}${this.currentInsight.description.length > 45 ? '...' : ''}`; 
    },
    userCanAuthorInsights() {
      return (this.$store.getters['allowedPermissions'] || {})[AUTHOR_INSIGHTS];
    },
    newAnnotationTypeName() {
      return NEW_ANNOTATION_TYPE_NAME;
    },
    insightHasNoPins() {
      return this.currentInsight && this.currentInsight?.pin_types.length == 0;
    },
    currentInsightIsSceneView() {
      return this.currentInsight?.sceneview_set.length > 0;
    },
    sortedPins() {
      return this.currentInsight.pins?.slice().sort((a,b) => a.tag - b.tag);
    },
    currentInsight() {
      let that = this;
      if (this.editInsightMode) {
        that.currentInsightStatus = (this.currentInsights[this.currentInsightIndex].insight_status == 'Published');
      }
      return this.currentInsights[this.currentInsightIndex];
    },
    title() {
      return this.currentInsight?.title;
    },
    description() {
      return this.currentInsight?.description;
    },
    servicelines() {
      if (!this.currentInsight) {
        return '';
      }
      return this.currentInsight.service_lines.map(serviceline => serviceline.name).join(', ');
    },
    activeLayers() {
      return this.currentInsight?.sceneview_set[0]?.active_layers;
    },
    updatedSimulationLabels() {
      if (this.selectedSimulationLabels.length == 0)
        return this.simulationLabels;
      else
        return this.selectedSimulationLabels.filter(simulationLabel => this.simulationLabels.map(a => a.configurationId).includes(simulationLabel.configurationId));
    },
    ...mapGetters('project', ['newAnnotationAdded', 'currentInsights', 'editInsightMode', 'createInsightMode', 'viewerMode', 'sceneViewCompositionMode', 'selectedStudy', 'selectedSimulationLabels', 'insightTypeSelected', 'currentInsight']),
  },
  methods: {
    handleInsightLayersPresent(insightLayersPresent){
      this.$emit('insightLayersPresent', insightLayersPresent);
    },
    enterEditInsightMode() {
      this.$store.dispatch('project/setEditInsightMode', true);
      this.$root.$emit('setAnnotationsPanelVisible', true);
    },
    getCurrentInsightPinsList() {
      let pins = this.currentInsight?.pins?.sort((a, b) => a.sort_order > b.sort_order ? 1 : -1);
      if(pins) {
        pins.forEach(pin => {
          if(!this.currentInsightPinsList.find(groupedPin => pin.group && pin.group == groupedPin.group))
            this.currentInsightPinsList.push(pin);
        });
      }
    },
    toggleAnnotationsContainer() {
      this.annotationsContainerExpanded = !this.annotationsContainerExpanded;
      const insightAnnotationsContainer = this.$refs.insight_annotations_container;
      if (this.annotationsContainerExpanded)
        insightAnnotationsContainer.style.height = '191%';
      else
        insightAnnotationsContainer.style.height = '80%';

      for (const pinType of this.currentInsight.pin_types) {
        this.togglePinTypeDetails(pinType, this.annotationsContainerExpanded);
      }
    },
    async updateInsight() {
      this.$root.$emit('saveInsight', this.currentInsight);
    },
    async deleteAnnotation(annotation) {
      annotation.deleteInProgress = true;
      this.$forceUpdate();
      let succeeded = await this.$store.dispatch('project/deleteInsightPin', {
        projectId: Number(this.$route.params.id),
        studyId: Number(this.$route.params.study),
        insightId: this.currentInsight.id,
        annotationId: annotation.id
      });

      if (succeeded) {
        this.$emit('removeAnnotationFromViewer', annotation);
      }
    },
    async updateAnnotation(pinId, pinTitle, pinDesc) {
      // update annotations
      let insightPinDetails = {
        title: pinTitle,
        description: pinDesc
      };

      await this.$store.dispatch('project/updateInsightPin', {
        projectId: Number(this.$route.params.id),
        studyId: Number(this.$route.params.study),
        insightId: this.currentInsight.id,
        insightPinId: pinId,
        updatedInsightPinDetails: insightPinDetails,
      });
    },
    async deleteAnnotationType(annotationType) {
      annotationType.deleteInProgress = true;
      this.$forceUpdate();
      let succeeded = await this.$store.dispatch('project/deleteInsightPinType', {
        projectId: Number(this.$route.params.id),
        studyId: Number(this.$route.params.study),
        insightId: this.currentInsight.id,
        annotationTypeId: annotationType.id
      });

      if (succeeded) {
        this.$emit('removeAnnotationTypeFromViewerAndCleanUpAnnotations', annotationType.id);
      }
    },
    toggleNav() {
      this.$emit('toggleInsightsPanel');
    },
    togglePinTypeDetails(annotationType, detailsOpen) {
      annotationType.detailsOpen = detailsOpen;
      this.$forceUpdate();
    },
    openDeleteAnnotationTypeModal(annotationTypeId) {
      this.$bvModal.show(`confirm-annotation-type-delete-${annotationTypeId}`);
    },
    async updateAnnotationType(pinTypeId, pinTypeTitle, pinTypeDesc) {
      // update annotations
      let insightPinTypeDetails = {
        name: pinTypeTitle,
        description: pinTypeDesc
      };

      await this.$store.dispatch('project/updateInsightPinType', {
        projectId: Number(this.$route.params.id),
        studyId: Number(this.$route.params.study),
        insightId: this.currentInsight.id,
        insightPinTypeId: pinTypeId,
        updatedInsightPinTypeDetails: insightPinTypeDetails,
      });
    },
    removingAllLayers(status) {
      this.$emit('removingAllLayers', status);
    }
  },
  watch: {
    newAnnotationAdded(newValue) {
      if (newValue == true) {
        this.currentInsightPinsList.push({
          colour: 'rgb(255, 0, 0)',
          tag: '1'
        });
        this.$store.dispatch('project/setNewAnnotationAdded', false);
      }
    },
    newInsightTitle(newValue) {
      this.$store.dispatch('project/setNewInsightTitle', newValue);
    },
    newInsightDescription(newValue) {
      this.$store.dispatch('project/setNewInsightDesc', newValue);
    },
    async currentInsight(newValue) {
      if(newValue) {
        this.annotationsContainerExpanded = false;
        await this.$nextTick();
        if (this.$refs.pinTypes) {
          this.$store.dispatch('project/setPinTypes', this.$refs.pinTypes);
        }
        this.currentInsightStatus = (newValue.insight_status=='Published');
        // if (this.simulationsFinishedLoading) {
        //   this.setCurrentInsightScenario();  //this wont' work for first load but it's called in the watch for simulationsFinishedLoading
        // THIS FUNCTION IS ACTUALLY NOT DEFINED HERE
        // }
      }
    },
    newAnnotationTitle(newValue) {
      if (newValue) {
        this.$store.dispatch('project/setNewAnnotationTitle', newValue);
      }
    },
    newAnnotationDescription(newValue) {
      if (newValue) {
        this.$store.dispatch('project/setNewAnnotationDesc', newValue);
      }
    },
    currentInsightIndex(newValue) {
      this.getCurrentInsightPinsList();
      this.$store.dispatch('project/switchInsight', newValue);
    },
    newInsightStatus(newValue) {
      if (newValue == true) {
        this.$store.dispatch('project/setNewInsightStatus', 'Published');
      } else {
        this.$store.dispatch('project/setNewInsightStatus', 'Draft');
      }
    },
    currentInsightStatus(newValue) {
      if (newValue) {
        this.currentInsight.insight_status = 'Published';
      } else {
        this.currentInsight.insight_status = 'Draft';
      }
    },
    createInsightMode(newValue) {
      if (!newValue) {
        this.newInsightTitle = null;
        this.newInsightDescription = null;
      }
    }
  }
};
</script>

<style>
.insights-panel {
  z-index: 10;
}

.scrollable-container {
  width: 100%;
  height: 50%;
  background-color: #F7F8F9;
  overflow-y: auto;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #8697A2 #ecf0f1; /* Firefox */
}

.scrollable-container-edit-mode {
  height: 46% !important;
  background-color: #F7F8F9;
  overflow-y: auto;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #8697A2 #ecf0f1; /* Firefox */
}

.toggle-sidebar-button:hover {
  background-color: #dbdbdb;
  color: #303030;
}

.annotation-link {
  outline: none; /* Remove the outline on focus */
  background-color: transparent !important; /* Remove background color */
  border-color: transparent !important; /* Remove border color */
}

.annotation-link:hover,
.annotation-link:focus {
  text-decoration: none; /* Remove the underline on hover and focus */
  color: inherit; /* Inherit the color from the parent */
}

.toggle-sidebar-button {
  color: #666;
  background-color: #f0f0f0;
  left: 0;
  height: 1.313rem;
  transition: width 0.3s;
  padding: 0 1rem;
  border: 0;
  display: flex;
  align-items: center;
  border-top: 0.063rem solid #dbdbdb;
  position: relative;
  bottom: 2.35em;
  left: 0;
}

h2 {
  color: var(--grey-900);
  font: normal normal bold 1.313rem/1.563rem Inter;
  opacity: 1;
}

.pin-tag {
  color: white;
  padding-left: 3px;
}

.insight-no-pins-conatiner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.annotations-header {
  padding-top: 3%;
}

.annotation-bg-circle {
  width: 27.62px;
  height: 27.62px;
}

.annotation-icon {
  width: 16px;
  height: 16px;
  padding: 6.31px;
  vertical-align: top;
}

.annotation-tag {
  color: white;
  font-family: Tahoma;
  width: inherit;
  line-height: 22.62px;
  text-align: center;
}

.annotation-desc {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  color: #596973;
}

.insight-annotations {
  overflow-y: auto;
  height: calc(100% - 1.313rem);
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #8697A2 #ecf0f1; /* Firefox */
}

.insight-annotations::-webkit-scrollbar, .scrollable-container::-webkit-scrollbar, .scrollable-container-edit-mode::-webkit-scrollbar {
  width: 8px; /* WebKit */
  background-color: transparent; /* Remove default background */
}

.insight-annotations::-webkit-scrollbar-thumb, .scrollable-container::-webkit-scrollbar-thumb, .scrollable-container-edit-mode::-webkit-scrollbar-thumb {
  background-color: #8697A2; /* WebKit */
  border-radius: 4px;
}

.insight-annotations::-webkit-scrollbar-track, .scrollable-container::-webkit-scrollbar-track, .scrollable-container::-webkit-scrollbar-track {
  background-color: transparent; /* WebKit */
}

.pin-icon-spacer {
  background-color: #596973;
  width: 17px;
  margin: 10px;
}

.insight-annotations-container {
  height: 76%;
  background-color: #E2E6E9;
  padding: 2%;
  border-radius: 4px;
}

.insight-annotations-container-2 {
  height: 186%;
  background-color: #E2E6E9;
  padding: 2%;
  border-radius: 4px;
}

.insight-title-bar-button {
  width: 1.5rem;
  min-width: 1.5rem;
  cursor: pointer;
}

.insight-title {
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  font-size: 20px;
  line-height: 24.2px;
  color: #596973;
}

.annotation-title {
  font-family: 'Inter', sans-serif;
  font-weight: 800 !important;
  font-size: 14px !important;
  line-height: 21.94px;
  color: #596973;
}

.dummy-annotation-title {
  color: var(--grey-600);
  font-size: 0.9rem !important;
  font-weight: normal !important;
}

.annotations-btn:hover {
  cursor: pointer;
}

.annotations-ctn-btn:hover {
  cursor: pointer;
}

.annotations-ctn-btn {
  color: #596973;
}

.pin-type-visibility {
  z-index: 1;
  color: #364046;
}

.insight-description {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  color: #596973;
  margin-right: 5%;
  margin-bottom: 2%;
}

.annotations {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 19.36px;
  color: #8697A2;
}

.insight-form-label {
  font-family: Inter;
  font-size: 0.89rem;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0em;
  text-align: left;
}

.insights-panel h3 {
  font-size: 0.925rem;
  color: var(--grey-900);
  font-weight: 660;
}

.insights-panel h4 {
  font-size: 1.25em;
}

.annotations-container {
  height: 43%;
  box-shadow: 0px -10px 10px -10px rgba(0, 0, 0, 0.3);
}

.closed-layers-panel:hover {
  background-color: #dbdbdb;
  color: #303030;
  cursor: pointer;
}

.insight-text-description {
  margin-bottom: 2%;
}

.closed-layers-panel {
  background-color: var(--grey-100);
  width: 0.938rem;
  min-width: 0.938rem;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  height: 100%;
}

.insights-panel h5 {
  font-size: 1em;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 0;
}

.layers-panel-content {
  height: calc(100% - 6.5rem);
  padding: 1rem;
}

#insight-status {
  margin-top: 0 !important;
  margin-left: 7.5rem;
  font-size: 90%;
  align-items: flex-end;
}
#insight-status-title {
  display: inline-block !important;
}
#publish-info-icon {
  margin-left: 2%;
}
#insight-status-switch {
  padding: 0 !important;
  border: 0 !important;
  display: inline !important;
}
#insight-status .custom-switch {
  margin-left: 0 !important;
}
label[for=insight-status-switch]::before {
  display: inline-block !important;
  margin-top: -1px;
}

label[for=insight-status-switch]::after {
  display: inline-block !important;
  margin-top: -0.1px;
}
</style>